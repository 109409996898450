/* @import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400;600;700;800&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&family=Orbitron:wght@500&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.0.0/animate.min.css");

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

pre {
  white-space: pre-wrap;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
}

.uc {
  text-transform: uppercase;
}

.orbitron {
  font-family: "Montserrat", sans-serif;
}

.check-indicator {
  position: absolute;
  width: 40px;
  height: 20px;
  border: 1px solid rgb(37, 45, 86);
  border-radius: 6px;
  left: calc(50% - 20px);
  bottom: -10px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.check-indicator.selected .check-indicator-inside {
  background: rgb(37, 45, 86);
  width: 32px;
  height: 12px;
  border-radius: 4px;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  display: flex;
  border-bottom: 2px solid #ccc;
}

.logo {
  width: 175px;
  padding: 15px 20px 10px 20px;
}

.logo-wrapper {
  display: flex;
  align-items: center;
}

.steps {
  display: flex;
  flex: 1;
  padding-left: 15px;
}

.step {
  cursor: pointer;
  margin: 15px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  padding: 15px 0;
  margin-bottom: 0;
  text-align: center;
}

.step-active {
  border-bottom: 3px solid;
}

.step-visited {
}

.option {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 10px 20px;
  padding: 11px;
}

.option.option-selected {
  border: 2px solid rgb(37, 45, 86);
  padding: 10px;
}

.content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.footer {
  height: 100px;
  border-top: 1px solid #000;
  background: #fff;
  color: #000;
  display: flex;
}

.footer .payment-options {
  padding: 5px 0;
}

.footer .price-summary {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  border-right: 1px solid #fff;
  padding: 1rem 3rem;
}

.footer .price-summary .price-text {
  font-size: 24px;
  font-weight: 500;
}

.footer .price-summary .estimate-payment a {
  color: #fff !important;
  font-size: 0.8em;
}

.footer .delivery {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 1rem 3rem;
  justify-content: flex-end;
}

.footer .delivery .text {
  text-align: right;
  font-size: 12px;
}

.button {
  box-shadow: none;
  border-radius: 30px;
  text-align: center;
  padding: 0.4rem 5rem;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
}

.red-button {
  background: #f00;
  color: #fff;
}

.white-button {
  background: #fff;
  color: #000;
  font-size: 14px !important;
  padding: 0.3rem 1rem !important;
}

.black-button {
  background: #000;
  color: #fff;
}

.button.wide {
  padding: 0.5rem 3rem;
  font-size: 20px;
  border: none;
  outline: 0;
}

.button.small {
  padding: 0.3rem 2rem;
  font-size: 14px;
}

.model-name {
  font-size: 32px;
  font-weight: 700;
  margin-left: 10px;
}

.step-content-inner {
  display: flex;
  flex: 1;
}

/* ImageStep */
.step-image-content {
  display: flex;
  flex: 1;
}

.step-image-content .left {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.step-image-content .image {
  display: flex;
  flex: 9;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  align-items: flex-end;
  justify-content: center;
}

.engine_info {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffffaa;
  border-radius: 36px 36px 0 0;
}

.engine_info__item {
  margin: 15px 0;
  padding: 0 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  border-right: 1px solid;
  width: 120px;
}

.engine-info__item:last-child {
  border: none;
}

.engine_info__item .price-text {
  font-size: 16px;
}

.engine_info__title {
  text-align: center;
  font-size: 16px;
}

.engine_info__value {
  text-align: center;
  font-weight: bold;
}

.step-image-content .options-wrapper {
  width: 500px;
  border-left: 1px solid #ccc;
  overflow: auto;
  padding: 20px 0;
}

.option-group-title {
  margin-top: 10px;
  color: #666;
  padding: 1rem 0;
}

.option-group-subtitle {
  margin-left: 15px;
  margin-right: 40px;
  margin-top: 35px;
  font-weight: bold;
  font-size: 24px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;
}

.option-title {
  font-size: 16px;
}

.option-price {
  font-size: 16px;
  color: #666;
}

.option-description pre {
  font-size: 10px;
}

.option-radio {
  cursor: pointer;
  align-items: center;
  border: 1px solid #333;
  margin: 10px;
  padding: 10px;
  border-radius: 12px;
  position: relative;
  margin-bottom: 25px;
}

.option-radio .option-title {
}

.option-radio .option-radio-description {
  font-size: 14px;
  margin-top: 5px;
}

.option-radio.option-selected,
.option-radio.option-package {
}

.option-radio-image {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.option-radio-image.image-top {
  flex-direction: column;
}

.option-checkbox-image.image-top {
  flex-direction: column;
}

.singleImage .options {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.option-group {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.option-checkbox-image {
  cursor: pointer;
  display: flex;
}

.multiImage .option-inner {
  padding-bottom: 15px;
}

.multiImage .option-price {
  bottom: 10px;
  right: 10px;
}

.step-grid-content {
  display: flex;
  flex: 1;
  overflow: auto;
  flex-direction: column;
}

.option-group.multiImage .option-group-options {
  padding-top: 0rem;
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.option-group.multi .option-group-options {
  display: grid;
  padding: 5rem;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-gap: 15px;
}

.option-checkbox {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
}

.option-checkbox .option-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.option-checkbox .option-description {
  flex: 1;
}

.option-checkbox .option-price {
  font-size: 1rem;
}

.option-checkbox pre {
  line-height: 1.5;
}

.option-checkbox.option-selected,
.option-checkbox.option-package {
  padding: 8px;
  border: 3px solid #b01813;
}

.option-group.multi,
.option-group.multiImage {
}

.option-checkbox .option-title {
}

.option-checkbox-image .option-inner {
}

.option-checkbox-image .option-image img {
  width: 150px;
}

.image-top .option-image img {
  width: 100%;
}

.option-checkbox-image .option-inner .option-image img {
  padding: 5px;
  width: 100%;
}

.option-checkbox-image.option-selected .option-inner .option-image img,
.option-checkbox-image.option-package .option-inner .option-image img {
  
}

.option-package-name {
  padding: 0;
  color: #d3282f;
  font-size: 12px;
}

.option-package {
  opacity: 0.5;
}

.step-summary-content {
  display: flex;
  flex: 1;
}

.upgradeoption-step {
  font-size: 16px;
  font-weight: 500;
}

.upgradeoption-option-group-title {
  font-size: 15px;
  margin-top: 5px;
}

.upgradeoption-option-group {
  font-size: 14px;
  margin-top: 10px;
}

.upgradeoption-option {
  font-size: 12px;
}

.step-summary-content .left {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.step-summary-content .heading {
  text-align: center;
}

.step-summary-content .heading h1 {
  font-size: 30px;
  margin-bottom: 10px;
}

.step-summary-content .heading h2 {
  font-size: 24px;
  margin-top: 0;
  font-weight: normal;
}

.step-summary-content .buttons {
  text-align: center;
  margin-bottom: 25px;
}

.step-summary-content .image {
  display: flex;
  flex: 9;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.step-summary-content .options-wrapper {
  width: 500px;
  border-left: 1px solid #ccc;
  overflow: auto;
  padding-bottom: 50px;
}

.step-summary-content .options-wrapper h2 {
  font-size: 50px;
  margin-top: 0px !important;
  margin-bottom: -20px;
}

.step-summary-content .options-wrapper h3 {
  font-size: 26px;
  margin-bottom: 15px;
}

.standards {
  display: flex;
}

.standards-left {
  margin-right: 15px;
}

.upgrades {
  display: grid;
  grid-template-columns: auto;
  padding: 1rem;
}

.upgrades .option {
  padding-bottom: 5px;
  padding-right: 10px;
  margin-bottom: 5px;
  font-size: 13px;
  align-items: center;
}

.upgrades .option-row {
  display: flex;
  padding-top: 10px;
  padding-bottom: 5px;
  align-items: center;
}

.upgrades .option .text {
  margin-right: 10px;
  font-weight: bold;
  flex: 1;
}

.upgrades .option .price {
  display: inline-block;
  color: #f00;
}

.step-payment-content {
  display: flex;
  flex: 1;
}

.step-payment-content .left {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 2rem 2rem;
  overflow: auto;
}

.step-payment-content .heading {
  text-align: center;
}

.step-payment-content .heading h1 {
  font-size: 52px;
  margin-bottom: 10px;
}

.step-payment-content .heading h2 {
  font-size: 40px;
  margin-top: 0;
  font-weight: normal;
}

.step-payment-content .buttons {
  text-align: center;
  margin-bottom: 25px;
}

.step-payment-content .image {
  display: flex;
  flex: 9;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.step-payment-content .options-wrapper {
  width: 500px;
  border-left: 1px solid #ccc;
  overflow: auto;
  padding: 1rem;
  padding-bottom: 50px;
}

.step-payment-content .options-wrapper h2 {
  font-size: 50px;
}

.step-payment-content .options-wrapper h3 {
  font-size: 26px;
  margin-bottom: 15px;
}

.step-payment-content .image {
  width: 100%;
}

.step-payment-content .purchase-wrapper {
  font-size: 20px;
  border-top: 1px solid;
  margin-top: 15px;
  padding-top: 15px;
}

.step-payment-content .purchase-wrapper .duetoday {
  font-size: 24px;
  font-weight: bold;
  margin-top: 15px;
}

.step-payment-content .purchase-footer-text {
  font-size: 13px;
  margin-top: 10px;
}

.step-payment-content .purchase-price {
  display: flex;
  justify-content: space-between;
}

.reservation {
  margin-top: 15px;
  font-size: 26px;
}

.step-summary-content .purchase-wrapper {
  font-size: 20px;
  border-top: 1px solid;
  margin-top: 15px;
  padding: 15px;
}

.step-summary-content .purchase-price {
  display: flex;
  justify-content: space-between;
}

.step-summary-content .purchase-price .purchase-price-amount {
  font-weight: bold;
}

.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.form .form-item {
  padding: 1rem;
  margin: 0 15px;
}

.form label {
  margin-bottom: 10px;
  display: block;
  font-size: 14px;
}

.form input {
  padding: 0.8rem;
  border-radius: 4px;
  width: 100%;
  outline: none !important;
  font-size: 16px;
  border: 1px solid #ccc;
}

.form select {
  padding: 0.8rem 1rem;
  border-radius: 4px;
  width: 100%;
  outline: none !important;
  font-size: 16px;
  border: 1px solid #ccc;
  background: #fff;
}

.step-payment-content .left h2 {
  font-size: 32px;
  padding-left: 1rem;
  text-align: center;
  text-transform: uppercase;
}

.step-payment-content .agreement-text {
  padding: 1rem;
  line-height: 2;
  font-size: 12px;
}

.step-complete-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.step-complete-content .complete-content {
  flex: 1;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  width: 900px;
  margin: 0 auto;
}

.step-complete-content .image {
  display: flex;
  flex: 2;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.error {
  padding: 1rem;
  background: #b01813;
  color: #fff;
}

.step-content-inner {
  display: none;
}

.flex {
  display: flex;
}

.title {
  display: none;
  padding: 1rem 2rem;
  color: #231f20;
  font-size: 20px;
  font-weight: bold;
  border: 1px solid silver;
  align-items: center;
}

.title svg {
  font-size: 24px;
}

.option-group.multi .option-group-options {
  padding: 2rem;
}

.option-title {
  font-size: 14px;
  margin-top: 0.5rem;
}

.model-name {
  text-align: center;
}

.option-group-title {
  color: #000;
}

.option-image {
}

.option-image img {
  margin-right: 10px;
}

.option-title {
  font-weight: bold;
}

.option-description {
  margin-top: 10px;
  font-size: 12px;
}

.option-radio-image .option-title {
  margin: 0;
}

.option-radio-image .option-description {
  margin: 0;
}

.option-content {
  padding: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.option-price {
  text-align: right;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.option-selector {
  width: 12px;
  height: 12px;
  padding: 3px;
  border-radius: 100%;
  border: 1px solid;
  display: inline-block;
  content: "";
  margin-left: 10px;
}

.option-selector.selected .tick {
  display: inline-block;
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 100%;
}

.option-selector.selected .tick {
  background: red;
}

.indicator {
  display: none;
}

.indicator2 {
  margin-right: 10px;
}

.modelSelector__left {
  flex: 1;
  overflow: auto;
}

.modelSelector__rightBackdrop {
  background: #000000a0;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.modelSelector__right {
  cursor: auto;
  width: 500px;
  border-left: 1px solid #000;
  overflow: auto;
  position: fixed;
  z-index: 9998;
  transition-duration: 0.6s;
  transition-timing-function: ease-in;
  right: -600px;
  top: 0;
  background: #fff;
  height: 100%;
  overflow-y: auto;
}

.open .modelSelector__right {
  right: 0px;
}

.modelSelector__title {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
}

.modelSelector__title strong {
  font-size: 32px;
}

.modelSelector__titleSlogan {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 26px;
}

.modelSelector__name {
  margin-top: 10px;
  font-size: 24px;
  text-align: center;
}

.modelSelector__slogan {
  font-size: 14px;
  margin-top: 10px;
}

.modelSelector__content_title {
  font-size: 12px;
  color: #999;
  font-weight: 500;
  display: inline-block;
  padding-bottom: 2px;
  margin-bottom: 3px;
  padding-right: 8px;
}

.modelSelector__length {
  font-size: 14px;
  margin-top: 10px;
}

.modelSelector__price {
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  text-align: center;
}

.modelSelector__cta {
  font-size: 12px;
  margin-top: 25px;
}

.modelSelector__items {
  padding: 35px 60px;
  padding-top: 0;
  display: grid;
  grid-template-columns: repeat(4, minmax(0px, 1fr));
  overflow-y: auto;
}

.open .modelSelector__items {
}

.modelSelector__item {
  padding: 25px 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  margin: 10px;
  position: relative;
}

.modelSelector__item.selected {
  border-color: rgb(37, 45, 86);
}

.modelSelector__item .modelSelector__image img {
}

.modelSelector__item.selected .modelSelector__image img {
  padding: 0;
}

.modelSelector__item.selected .indicator {
  display: inline-block;
  font-size: 16px;
  margin-right: 10px;
}

.modelSelector__image {
  flex: 2;
}

.modelSelector__content {
  flex: 1;
}

.modelSelector__image img {
  width: 100%;
}

.modelSelector__footer {
  background: #fff;
  border-top: 1px solid #000;
}

.selectedModel__title {
  margin-top: 20px;
  font-size: 28px;
}

.selectedModel__subtitle--top {
  letter-spacing: 2px;
  height: 14px;
  margin-left: 15px;
}

.selectedModel__subtitle--bottom {
  margin-left: 15px;
  margin-top: 10px;
  font-size: 12px;
}

.selectedModel__tech {
  margin-top: 25px;
  margin-bottom: 15px;
}

.selectedModel__standardspecs {
  padding: 20px;
}

.selectedModel__specs {
  display: grid;
  grid-template-columns: repeat(3, minmax(0px, 1fr));
  padding: 20px;
}

.selectedModel__spec {
  margin-bottom: 15px;
}

.selectedModel__spec--key {
  font-weight: bold;
}

.selectedModel__spec--text {
}

.selectedModel__tabs--item {
  margin-bottom: 10px;
}

.selectedModel__tabs--item.selected .selectedModel__tabs--content {
  display: block;
}

.selectedModel__tabs--title {
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 10px;
}

.selectedModel__tabs--title svg {
  margin-right: 10px;
}
.selectedModel__tabs--content {
  display: none;
  border-top: 2px solid;
}

.selectedModel__tabs--content pre {
  line-height: 1.5;
  font-size: 13px;
}

.selectedModel__engine {
  padding: 10px 20px;
}

.selectedModel__engine table tr td:first-child {
  padding-right: 25px;
  font-weight: bold;
}

.modelSelector__footer {
  display: flex;
  justify-content: space-between;
  color: #000;
  align-items: center;
  padding: 0 25px;
  z-index: 9999;
}

.button-wrapper {
  display: flex;
}

.modelSelector__button {
  display: inline-block;
  background: red;
  color: #fff;
  text-decoration: none !important;
  border-radius: 6px;
  transform: skew(-30deg);
  margin-right: 30px;
  cursor: pointer;
}

.modelSelector__cleanbutton {
  border: 1px solid #000;
  letter-spacing: 2px;
  font-size: 14px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  transition-duration: 0.6s;
  user-select: none;
  opacity: 0.9;
}

.modelSelector__cleanbutton.blue {
  background-color: rgb(37, 45, 86);
  border-color: rgb(37, 45, 86);
}

.modelSelector__cleanbutton.red {
  background-color: rgb(177, 59, 62);
  border-color: rgb(177, 59, 62);
}

.modelSelector__cleanbutton a {
  padding: 10px 10px;
  display: inline-block;
  text-decoration: none !important;
  min-width: 150px;
  text-align: center;
  color: #000;
}

.modelSelector__cleanbutton.blue a {
  color: #fff;
}

.modelSelector__cleanbutton.red a {
  color: #fff;
}

.modelSelector__cleanbutton button {
  background: none;
  border: none;
  outline: none;
  padding: 10px 30px;
  display: inline-block;
  text-decoration: none !important;
  min-width: 150px;
  text-align: center;
  color: #000 !important;
  cursor: pointer;
}

.modelSelector__cleanbutton.disabled {
  color: #ccc !important;
  border-color: #ccc;
}

.modelSelector__cleanbutton.disabled a {
  color: #ccc !important;
}

.modelSelector__cleanbutton:hover {
  opacity: 1;
}

.button__clean {
  padding: 10px 70px;
  border: 1px solid #000;
  letter-spacing: 2px;
  font-size: 14px;
  border-radius: 3px;
  cursor: pointer;
  color: #000;
  font-weight: 500;
  transition-duration: 0.6s;
  background: #fff;
}

.modelSelector__button.dark {
  background: #000;
}

.modelSelector__button a {
  color: #fff;
  text-decoration: none !important;
  display: inline-block;
  transform: skew(30deg);
  padding: 10px 80px;
  font-weight: bold;
}

.modelSelector__rightLogo {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.option-additional-title {
  margin-left: 10px;
  margin-top: 15px;
  margin-bottom: 5px;
  font-weight: bold;
  text-transform: uppercase;
}
.option-additional .option-title {
  margin-top: 0;
  flex: 1;
}

.option-additional .option-content {
  border: 1px solid;
  background: #fff;
  margin: 10px;
  align-items: center;
  flex-direction: row;
  padding: 0;
  padding-right: 10px;
}

.option-additional .option-price {
  margin-top: 0;
}

.option-additional-text {
  width: 100%;
  border: 0;
  outline: none;
  padding: 10px;
}

.mobile-title {
  padding: 20px;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1000;
  font-size: 24px;
  box-shadow: 0 5px 5px 0px #00000033;
}

.model-name {
  display: none;
}

.mobile {
  display: none;
}

.option-picker {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 7px;
}

.option-picker .option-image {
  width: 40px;
  height: 40px;
  padding: 3px;
  border-radius: 100%;
  cursor: pointer;
  transition-duration: 0.6s;
  border: 3px solid #ffffff;
}

.option-picker.option-selected .option-image {
  padding: 3px;
  border: 3px solid red;
}

.option-picker img {
  width: 40px;
}

.picker .options {
  display: grid;
  grid-template-columns: repeat(4, 2fr);
}

.brand-logo {
  margin-left: 35px;
  padding-right: 10px;
}

.selectedModel__subtitle {
  flex: 1;
}

.m-only {
  display: none;
}

.modelSelector__right--close {
  position: absolute;
  top: 20px;
  right: 10px;
}

.mobile-title .pricing {
  display: none;
}

.buttons_mobile {
  display: none;
}

@media screen and (max-width: 992px) {
  .loading {
    margin-top: 100%;
  }

  .m-only {
    display: initial;
  }

  .modelSelector__title {
    font-size: 18px;
    margin-bottom: 0;
  }

  .modelSelector__footer {
    padding: 0;
  }

  .modelSelector__footer--right {
    margin-right: 5px;
  }

  .modelSelector__titleSlogan {
    font-size: 18px;
    margin-top: 0;
  }

  .modelSelector__title strong {
    font-size: 26px;
  }

  .modelSelector__item {
    flex-direction: column;
  }

  .step-image-content .image {
    display: none;
  }

  .brand-logo {
    margin: 0;
  }

  .brand-logo img {
    height: 20px !important;
  }

  .step-payment-content .left {
    padding: 2rem 1rem;
  }

  .selectedModel__subtitle--top {
    letter-spacing: initial;
  }

  .selectedModel__subtitle--bottom {
    letter-spacing: initial;
  }

  .dependency__dependencies {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .dependants__option--title {
    margin-left: 10px;
  }

  .spec_popup__close {
    right: 3% !important;
    top: 75px !important;
  }

  .spec_popup__container {
    flex-direction: column;
    z-index: 1000000;
    padding-top: 0 !important;
  }

  .upgradeoption-edit {
    display: none;
  }

  .spec_popup__image {
    flex: initial !important;
  }

  .spec_popup__modal {
    padding: 0 !important;
  }

  .spec_popup__content {
    padding: 0;
    margin-top: 15px;
  }

  .price-summary .payment-options,
  .price-summary .price-display {
    display: none;
  }

  .estimated {
    padding-right: 10px;
  }

  .option-additional-text {
    font-size: 9px;
  }

  .option-additional-title {
    font-size: 12px;
  }

  .title {
    padding: 1rem;
  }

  .option-group-subtitle {
    font-size: 18px;
    margin-top: 10px;
  }

  .step-summary-content .heading h1 {
    font-size: 22px;
  }

  .step-payment-content .left h2 {
    font-size: 22px;
  }

  .mobile-title {
    display: flex;
    align-items: center;
  }

  .mobile-title .pricing {
    flex: 1;
    font-size: 14px;
    text-align: right;
  }

  .form input,
  .form select {
    padding: 10px 0;
  }

  .form .form-item {
    padding: 6px;
  }

  .form label {
    margin-bottom: 5px;
    color: #444;
  }

  .step-payment-content .options-wrapper {
    display: none;
  }

  .button.wide {
    padding: 0.4rem 5rem;
  }

  .buttons_mobile {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .payment-options {
    font-size: 12px;
    display: inline-block;
    margin-right: 15px;
  }

  .price-display {
    display: inline-block;
  }

  .modelSelector__button.mobile {
    margin-top: 15px;
    margin-right: 0;
  }

  .footer .price-summary .price-text {
    font-size: 16px;
    display: inline-block;
  }

  .mobile {
    display: inline-block;
  }

  .title {
    display: flex;
  }

  .flex {
    display: block;
  }

  .app {
    display: block;
    min-height: 100vh;
    height: auto;
  }

  .content {
    display: block;
    padding-bottom: 100px;
  }

  .steps {
    display: none;
  }

  .header {
    justify-content: center;
  }

  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    align-items: center;
  }

  .footer .price-summary {
    display: block;
    padding: 1rem;
  }

  .footer .payment-options {
    padding: 0;
  }

  .footer .delivery {
    display: none;
  }

  .footer .estimate-payment {
    display: none;
  }

  .step-content {
    display: block;
  }

  .button.wide {
    margin: 10px;
    display: block;
  }

  .step-content-inner.visible-mobile {
    display: block !important;
  }

  .step-payment-content .left {
    display: block;
  }

  .step-grid-content {
    display: block;
  }

  .option-group .group-optiongroup {
    display: grid;
    padding: 1rem;
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .step-summary-content,
  .step-payment-content {
    display: block;
  }

  .standards {
    display: block;
  }

  .upgrades {
    grid-template-columns: auto;
  }

  .form {
    grid-template-columns: auto;
  }

  .step-summary-content .options-wrapper,
  .step-payment-content .options-wrapper {
    width: auto;
  }

  .option-group.multi .option-group-options {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .option-group.multiImage .option-group-options {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .standards,
  .step-payment-content,
  .step-summary-content {
    padding: 0.5rem;
  }

  .step-complete-content {
    display: block;
  }

  .modelSelector__name {
    font-size: 18px;
    padding-bottom: 15px;
  }

  .modelSelector__items {
    grid-template-columns: auto auto;
  }

  .modelSelector__right {
    width: auto;
  }

  .selectedModel__specs {
    grid-template-columns: auto auto;
  }

  .selectedModel__tabs--item .tabs-icon {
    float: left;
    margin-left: 0;
    margin-right: 10px;
  }

  .selectedModel__tabs--title {
    font-size: 14px;
  }

  .selectedModel__tabs--content pre {
    font-size: 13px;
  }

  .option-image img {
    max-width: 100%;
  }

  .step-complete-content .complete-content {
    width: auto;
    padding: 25px;
  }

  .step-complete-content .complete-content .modelSelector__button {
    margin-top: 25px;
  }

  .step-complete-content .complete-content .modelSelector__button a {
    padding: 10px 30px;
  }
}

@media screen and (max-width: 768px) {
  .modelSelector__items {
    grid-template-columns: auto;
  }
}
